import React from "react";
import Helmet from "react-helmet";
import DefaultLayout from "../layouts/DefaultLayout";
import PostList from "../components/PostList";
import Paginator from "../components/Paginator";

const IndexPage = ({ pageContext }) => {
  const { group, index, pageCount } = pageContext;
  const newerUrl = index - 1 === 1 ? "" : (index - 1).toString();
  const olderUrl = (index + 1).toString();

  return (
    <DefaultLayout headerClassName="header-has-logo default" archiveYears={pageContext.additionalContext.archiveYears}>
      <Helmet title={pageContext.additionalContext.site.siteMetadata.title} />
      <PostList posts={group} />
      <Paginator
        index={index}
        pageCount={pageCount}
        newerUrl={newerUrl}
        olderUrl={olderUrl}
      />
    </DefaultLayout>
  );
};

export default IndexPage;
